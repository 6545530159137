
import { defineComponent, ref, getCurrentInstance } from 'vue';
import { listTags, addTag, updateTag, deleteTags, checkTagRule } from '@/api/settings/tags';
import { userInfo } from '@/layout/messageCenter/user-info';
import PackagedPagination from '@/components/pagination/Index.vue';
import _ from 'lodash/fp';
import { ElMessageBox, ElMessage } from 'element-plus';
import { getShowBool } from '@/utils/permission-show-module';
import { useSaList } from '@/shared/hooks/list';

// 状态码
enum ResCode {
  Success,
}
export default defineComponent({
  name: 'Tag',
  components: {
    PackagedPagination,
  },
  setup() {
    // 获取组件实例
    const instance = getCurrentInstance();
    // 表格数据
    let sortType: string;
    let sortField: string;
    const submitting = ref(false);
    const columns = [
      {
        label: '标签名称',
        prop: 'name',
      },
      {
        label: '创建时间',
        prop: 'createTime',
      },
    ];
    const format = (num: number): string => {
      if (num < 10) {
        return `0${num}`;
      }
      return num.toString();
    };
    const dateFormat = (timestamp: number | string): string => {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = format(date.getMonth() + 1);
      const day = format(date.getDate());
      const hour = format(date.getHours());
      const minute = format(date.getMinutes());
      const second = format(date.getSeconds());
      return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
    };

    const { loading, query, list, total, fetchList, handleSearch, handlePageChange, handlePageSizeChange } = useSaList(
      async (query: any) => {
        loading.value = true;
        try {
          const { code, data } = await listTags({
            ...query,
            sortType,
            sortField,
          });

          if (code === 0) {
            total.value = data.count;
            list.value = data.rows.map((row: any) => ({
              ...row,
              createTime: dateFormat(row.createTime),
            }));
          }
        } catch (err) {
          console.log(err);
        }
        loading.value = false;
      },
    );

    fetchList();

    // 排序
    const sortChange = (val: any) => {
      sortType = val.order;
      sortField = val.prop;
      fetchList();
    };

    // 批量删除
    let selection: Array<number> = [];
    const disabled = ref(true);
    const handleSelectionChange = (val: any) => {
      selection = _.map('id')(val);
      disabled.value = !selection.length;
    };
    const groupRemove = async (ids = selection) => {
      ElMessageBox.confirm(`是否删除选中标签?`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        loading.value = true;
        const { code } = await deleteTags({ ids });
        loading.value = false;
        if (code === 0) {
          (instance as any).proxy.$message({
            type: 'success',
            message: '删除成功',
          });
          fetchList();
        } else {
          (instance as any).proxy.$message({
            type: 'error',
            message: '删除失败',
          });
        }
      });
    };

    // 编辑弹窗
    const formRef: any = ref(null);
    const tagName = ref(null as any);
    const dialogVisible = ref(false);
    const dialogTitle = ref('');
    const form = ref({
      name: '',
    });
    const rules = {
      name: [{ required: true, message: '请输入标签名称', trigger: 'blur' }],
    };
    let id = '';
    const rename = (row: any) => {
      form.value.name = row.name;
      dialogVisible.value = true;
      dialogTitle.value = '编辑标签';
      id = row.id;
    };
    const add = () => {
      form.value.name = '';
      dialogVisible.value = true;
      dialogTitle.value = '新增标签';
    };
    // 标签重复校验
    const validatorTagsPass = async (rule: any, value: string, callback: Function) => {
      const { code, data } = await checkTagRule({
        name: value,
      });
      if (code === ResCode.Success && !data.usable) {
        callback(new Error('标签已存在!'));
      }
      callback();
    };
    const save = () => {
      tagName.value.handleBlur();
      if (submitting.value || form.value.name.length > 25 || form.value.name.length < 1) {
        return;
      }
      formRef.value.validate(async (isValid: boolean) => {
        if (isValid) {
          submitting.value = true;
          loading.value = true;
          const { code } =
            dialogTitle.value === '新增标签'
              ? await addTag({ name: form.value.name })
              : await updateTag({ id, name: form.value.name });
          loading.value = false;
          if (code === 0) {
            dialogVisible.value = false;
            dialogTitle.value === '新增标签' ? ElMessage.success('新增标签成功') : ElMessage.success('编辑标签成功');
            submitting.value = false;
            fetchList();
          }
        }
      });
    };
    const cancel = () => {
      dialogVisible.value = false;
      formRef.value.resetFields();
    };

    return {
      columns,
      loading,
      query,
      list,
      total,
      fetchList,
      handleSearch,
      handlePageChange,
      handlePageSizeChange,
      handleSelectionChange,
      groupRemove,
      dialogVisible,
      dialogTitle,
      form,
      rules,
      rename,
      add,
      save,
      cancel,
      formRef,
      tagName,
      sortChange,
      disabled,
      getShowBool,
      submitting,
      validatorTagsPass,
      userInfo,
    };
  },
});
