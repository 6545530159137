import { reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { debounce } from 'lodash';

/**
 * 封装数据列表相关
 * @param fetchHandle
 * @param initialValue
 * @returns
 */
export const useSaList = (fetchHandle: Function, initialValue?: { pageSize?: number; keyword?: string }) => {
  const list = ref([]);
  const total = ref(0);
  const loading = ref(false);
  const route = useRoute();
  const router = useRouter();
  const { page } = route.query;
  let p = page ? parseInt(page as string, 10) : 1;
  if (isNaN(p)) {
    p = 1;
  }

  // 基础query，其他query参数业务端自己搞定
  const query = reactive({
    keyword: '',
    pageSize: 10,
    ...(initialValue || {}),
    page: p,
  });

  // list, total, loading 由业务端去处理
  const fetchList = async (...args: any) =>
    fetchHandle(
      {
        ...query,
      },
      ...args,
    );

  // 在路由中带入当前分页参数
  const pushRouter = () => {
    router.push({
      ...route,
      query: {
        ...route.query,
        page: query.page,
      },
    });
  };

  // 搜索
  const handleSearch = debounce(() => {
    query.page = 1;
    pushRouter();
    fetchList();
  }, 500);

  // 每页条数改变
  const handlePageSizeChange = (pageSize: number) => {
    query.pageSize = pageSize;
    fetchList();
  };

  // 页数切换
  const handlePageChange = (pageNum: number) => {
    query.page = pageNum;
    pushRouter();
    fetchList();
  };

  watch(
    () => [list.value.length, total.value],
    () => {
      // 防止页码过期，比如URL中缓存了一个大页码，返回不了数据，但是总数还是有的情况
      if (list.value.length === 0 && total.value > 0) {
        query.page = 1;
        pushRouter();
        fetchList();
      }
    },
  );

  return {
    list,
    total,
    loading,
    query,
    fetchList,
    handleSearch,
    handlePageSizeChange,
    handlePageChange,
  };
};
